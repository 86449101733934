import React, { useEffect } from "react";
import * as Icons from "@wix/wix-ui-icons-common";
import {
  Box,
  Button,
  Card,
  WixDesignSystemProvider,
  MarketingPageLayout,
  MarketingPageLayoutContent,
  Page,
  Loader,
  Modal,
  CustomModalLayout,
  MarketingLayout,
  Cell
} from "@wix/design-system";
import "@wix/design-system/styles.global.css";
import { ReactTagManager } from "react-gtm-ts";
import * as basePlatform from "@certifiedcode/base";
import { useTranslation } from "react-i18next";
const tagManagerArgs = {
  code: "GTM-WZQPMC7",
};

function App() {
  const { t } = useTranslation()
  ReactTagManager.init(tagManagerArgs);
  const [InteractiveDemoOpen, setInteractiveDemoOpen] =
    React.useState(false);
  const token = new URLSearchParams(window.location.search).get("token");
  const instance = new URLSearchParams(window.location.search).get("instance");
  const [isUpgraded, setIsUpgraded] = React.useState(false);
  const [instanceData, setInstanceData] = React.useState({
    instance: {
      isFree: true,
      availablePlans: [],
      instanceId: "",
    },
  } as any);
  const openInteractiveDemo = () => {
    setInteractiveDemoOpen(true);
  };
  useEffect(() => {
    basePlatform.initialize({
      baseURL: "https://certifiedcode.wixsite.com/w-cse/_functions", // Replace with your API base URL
      applicationID: "aeb9ae41-dab9-466b-aa87-b503276bd64e", // Replace with your application ID
    });
    basePlatform.setIdentity(instance);
    basePlatform.setInstance(setInstanceData, setIsUpgraded);
  }, []);

  if (token) {
    return (
      <WixDesignSystemProvider features={{ newColorsBranding: true }}>
        <Page height="100vh">
          <Page.Content>
            <Box height={"90vh"} direction="vertical" verticalAlign="middle">
              <Loader text={t('loading')} />
            </Box>
          </Page.Content>
        </Page>
      </WixDesignSystemProvider>
    );
  }

  return (
    <WixDesignSystemProvider features={{ newColorsBranding: true }}>
      {/* interactive demo Modal*/}
       <Modal
            isOpen={InteractiveDemoOpen}
            screen="desktop"
            shouldDisplayCloseButton={true}
            shouldCloseOnOverlayClick={true}
            onRequestClose={() => setInteractiveDemoOpen(false)}
          >
             <CustomModalLayout
              removeContentPadding={true}
              content={
                <div style={{ position: "relative", paddingBottom: "calc(56.22% + 41px)", height: 0, width: "100%" }}>
                  <iframe
                    src="https://app.arcade.software/share/WkuV5CWJGNctdwFfCgHr?embed&embed_mobile=inline&embed_desktop=inline&show_copy_link=true"
                    title="Get Paid with Calendly Scheduling | Getting Started"
                    frameBorder="0"
                    loading="lazy"
                    allow="clipboard-write"
                    allowFullScreen
                    style={{ position: "absolute", top: 0, left: 0, width: "100%", height: "100%", colorScheme: "light" }}
                  ></iframe>
                </div>
              }
            />
          </Modal>
          {/* interactive demo modal */}
      <Card>
        <MarketingPageLayout
          removeImageHorizontalPadding
          removeImageVerticalPadding
          content={
            <Box height="340px" verticalAlign="middle">
              <MarketingPageLayoutContent
                title={t('calendly-title')}
                content={t('calendly-content')}
                actions={
                  <Box gap="SP2">
                    <Button
                      suffixIcon={<Icons.Edit />}
                      as="a"
                      href={`https://www.wix.com/editor/${instanceData?.site?.siteId}`}
                      target="_blank"
                    >
                      {t('calendly-edit-button')}
                    </Button>
                    <Button
                      suffixIcon={<Icons.PremiumFilled />}
                      skin={isUpgraded ? "premium-light" : "premium"}
                      as="a"
                      href={basePlatform.getUpgradeUrl()}
                      target="_blank"
                    >
                      {isUpgraded ? t('manage-plan') : t('upgrade-live')}
                    </Button>
                  </Box>
                }
              />
            </Box>
          }
        />
      </Card>
      <Card>
        <MarketingPageLayout
          size="medium"
          removeImageHorizontalPadding
          removeImageVerticalPadding
          content={
            <Box height="340px" verticalAlign="middle">
              <MarketingPageLayoutContent
                title="Learn how to setup Calendly Scheduling"
                content="Estimated time: 5 minutes"
                actions={
                  <Box gap="SP2">
                    <Button
                      size="small"
                      skin="premium"
                      onClick={openInteractiveDemo}
                      >
                        Watch video
                    </Button>
                  </Box>
                }
              />
            </Box>
          }
        />
      </Card>
        {/* interactive demo Cell */}
        {/* <Cell span={12}>
              <Card>
                <MarketingLayout
                  size="tiny"
                  actions={
                    <Box padding="-30px" verticalAlign="middle">
                      <Box marginTop="3px">
                        <Button
                          size="small"
                          skin="premium"
                          onClick={openInteractiveDemo}
                        >
                          Watch video
                        </Button>
                      </Box>
                    </Box>
                  }
                  title="Learn how to setup Calendly Scheduling"
                  description="Estimated time: 5 minutes"
                ></MarketingLayout>
              </Card>
            </Cell> */}
             {/* interactive demo Cell */}
    </WixDesignSystemProvider>
  );
}

export default App;
